import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    lpSymbol: 'XCAO-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x443f60a30be143b587bd21b662ff56172c5a3475',
    },
    token: tokens.xcao,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 15,
    lpSymbol: 'XCAO',
    isTokenOnly: true,
    lpAddresses: {
      97: '',
      56: '0x97B141355DF5C6FF18EE0EAce816DC0C7e2d016c', /* 0x587B5FE3c51BDFf652E525FDcfddF59b8011e40e */
    },
    token: tokens.xcao,
    quoteToken: tokens.busd,
  },
  {
    pid: 1,
    lpSymbol: 'XCAO-CAKE LP',
    lpAddresses: {
      97: '',
      56: '0x773f442202Ce127f7527F8efc82fdb27168995e0',
    },
    token: tokens.xcao,
    quoteToken: tokens.busd,
  },
  {
    pid: 2,
    lpSymbol: 'XCAO-BTCB LP',
    lpAddresses: {
      97: '',
      56: '0x7189BC281542aAA598cBeA114E044a9751550d51',
    },
    token: tokens.xcao,
    quoteToken: tokens.btcb,
  },
  
  {
    pid: 3,
    lpSymbol: 'XCAO-USDT LP',
    lpAddresses: {
      97: '',
      56: '0x6BFb4f7dB1d467bCb6998637a85174c55449C8f1',
    },
    token: tokens.xcao,
    quoteToken: tokens.usdt,
  },
  {
    pid: 4,
    lpSymbol: 'XCAO-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x97B141355DF5C6FF18EE0EAce816DC0C7e2d016c',
    },
    token: tokens.xcao,
    quoteToken: tokens.busd,
  },
  {
    pid: 5,
    lpSymbol: 'XCAO-USDC LP',
    lpAddresses: {
      97: '',
      56: '0xaC014E145a2689a341A90963219F9Fd6787dA80f',
    },
    token: tokens.xcao,
    quoteToken: tokens.usdc,
  },
  {
    pid: 6,
    lpSymbol: 'BNB-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    },
    token: tokens.busd,
    quoteToken: tokens.wbnb,
  },
  {
    pid: 7,
    lpSymbol: 'wBNB',
    isTokenOnly: true,
    lpAddresses: {
      97: '',
      56: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16', /* 0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c */
    },
    token: tokens.wbnb,
    quoteToken: tokens.busd,
  },
  {
    pid: 8,
    lpSymbol: 'BTCB',
    isTokenOnly: true,
    lpAddresses: {
      97: '',
      56: '0xF45cd219aEF8618A92BAa7aD848364a158a24F33', /* 0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c */
    },
    token: tokens.btcb,
    quoteToken: tokens.busd,
  },
  {
    pid: 9,
    lpSymbol: 'CAKE',
    isTokenOnly: true,
    lpAddresses: {
      97: '',
      56: '0x804678fa97d91B974ec2af3c843270886528a9E6', /* 0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82 */
    },
    token: tokens.cake,
    quoteToken: tokens.busd,
  },
  {
    pid: 10,
    lpSymbol: 'USDT',
    isTokenOnly: true,
    lpAddresses: {
      97: '',
      56: '0xc15fa3E22c912A276550F3E5FE3b0Deb87B55aCd', /* 0x55d398326f99059fF775485246999027B3197955 */
    },
    token: tokens.usdt,
    quoteToken: tokens.busd,
  },
  {
    pid: 11,
    lpSymbol: 'BUSD',
    isTokenOnly: true,
    lpAddresses: {
      97: '',
      56: '0xc15fa3E22c912A276550F3E5FE3b0Deb87B55aCd', /* 0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56 */
    },
    token: tokens.busd,
    quoteToken: tokens.busd,
  },
  {
    pid: 12,
    lpSymbol: 'USDC',
    isTokenOnly: true,
    lpAddresses: {
      97: '',
      56: '0x680Dd100E4b394Bda26A59dD5c119A391e747d18', /* 0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d */
    },
    token: tokens.usdc,
    quoteToken: tokens.busd,
  },
  {
    pid: 13,
    lpSymbol: 'ETH',
    isTokenOnly: true,
    lpAddresses: {
      97: '',
      56: '0xd9A0d1F5e02dE2403f68Bb71a15F8847A854b494', /* 0x2170Ed0880ac9A755fd29B2688956BD959F933F8 */
    },
    token: tokens.eth,
    quoteToken: tokens.busd,
  },
  {
    pid: 14,
    lpSymbol: 'LINK',
    isTokenOnly: true,
    lpAddresses: {
      97: '',
      56: '0x00C4849E82D574B02f43c0F84b131dCC9cAbFC49', /* 0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD */
    },
    token: tokens.link,
    quoteToken: tokens.busd,
  },
]

export default farms
