import { MenuEntry } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

const config: (t: ContextApi['t']) => MenuEntry[] = (t) => [
  {
    label: t('Home'),
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: t('Swap'),
    icon: 'TradeIcon',
    items: [
      {
        label: t('Exchange'),
        href: '/swap?outputCurrency=0x587B5FE3c51BDFf652E525FDcfddF59b8011e40e',
      },
      {
        label: t('Liquidity'),
        href: '/add/BNB/0x587B5FE3c51BDFf652E525FDcfddF59b8011e40e',
      },
    ],
  },
  {
    label: t('Farms'),
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: t('Pools'),
    icon: 'PoolIcon',
    href: '/pools',
  },
  // {
  //   label: t('Referral'),
  //   icon: 'GroupsIcon',
  //   href: '/referral',
  // },
  // {
  //   label: t('Prediction (BETA)'),
  //   icon: 'PredictionsIcon',
  //   href: '/prediction',
  // },
  {
    label: t('Lottery'),
    icon: 'TicketIcon',
    href: '/lottery',
  },
  // {
  //   label: t('Games'),
  //   icon: 'PredictionsIcon',
  //   href: '/games',
  // },
  // {
  //   label: t('NFTs'),
  //   icon: 'NftIcon',
  //   href: '/collectibles',
  // },
  // {
  //   label: t('Launchpad'),
  //   icon: 'IfoIcon',
  //   href: '/launchpad',
  // },
  // {
  //   label: t('Info'),
  //   icon: 'InfoIcon',
  //   items: [
  //     {
  //       label: t('Techrate Audit'),
  //       href: 'https://swap.xcaoswap.finance/pdf/techrate.pdf',
  //     },
  //     {
  //       label: t('DexGuru'),
  //       href: 'https://dex.guru/token/0x587B5FE3c51BDFf652E525FDcfddF59b8011e40e-bsc',
  //     },
  //     {
  //       label: t('PooCoin'),
  //       href: 'https://poocoin.app/tokens/0x587B5FE3c51BDFf652E525FDcfddF59b8011e40e',
  //     },
  //     {
  //       label: t('Dextools'),
  //       href: 'https://www.dextools.io/app/bsc/pair-explorer/0x587B5FE3c51BDFf652E525FDcfddF59b8011e40e',
  //     },
  //     {
  //       label: t('BSCscan'),
  //       href: 'https://bscscan.com/token/0x587B5FE3c51BDFf652E525FDcfddF59b8011e40e',
  //     },
  //   ],
  // },
  // {
  //   label: t('More'),
  //   icon: 'MoreIcon',
  //   items: [
  //     {
  //       label: t('Github'),
  //       href: 'https://github.com/XcaoSystem',
  //     },
  //     {
  //       label: t('Docs'),
  //       href: 'https://swap.xcaoswap.finance/docs',
  //     },
  //     // {
  //     //   label: t('News'),
  //     //   href: 'https://medium.com/@XCAO SYSTEM',
  //     // },
  //   ],
  // }
]

export default config
