import { ContextApi } from 'contexts/Localization/types'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'XCAO SYSTEM',
  description:
    'The most popular AMM on BSC by user count! Earn XCAO through yield farming or win it in the Lottery, then stake it in Syrup Pools to earn more tokens! Initial Farm Offerings (new token launch model pioneered by XCAO SYSTEM), NFTs, and more, on a platform you can trust.',
  image: 'https://swap.xcaoswap.finance/images/hero.png',
}

export const getCustomMeta = (path: string, t: ContextApi['t']): PageMeta => {
  switch (path) {
    case '/':
      return {
        title: `${t('Home')} | ${t('XCAO SYSTEM')}`,
      }
    case '/competition':
      return {
        title: `${t('Trading Battle')} | ${t('XCAO SYSTEM')}`,
      }
    case '/prediction':
      return {
        title: `${t('Prediction')} | ${t('XCAO SYSTEM')}`,
      }
    case '/farms':
      return {
        title: `${t('Farms')} | ${t('XCAO SYSTEM')}`,
      }
    case '/pools':
      return {
        title: `${t('Pools')} | ${t('XCAO SYSTEM')}`,
      }
    case '/lottery':
      return {
        title: `${t('Lottery')} | ${t('XCAO SYSTEM')}`,
      }
    case '/collectibles':
      return {
        title: `${t('Collectibles')} | ${t('XCAO SYSTEM')}`,
      }
    case '/ifo':
      return {
        title: `${t('Initial Farm Offering')} | ${t('XCAO SYSTEM')}`,
      }
    case '/teams':
      return {
        title: `${t('Leaderboard')} | ${t('XCAO SYSTEM')}`,
      }
    case '/profile/tasks':
      return {
        title: `${t('Task Center')} | ${t('XCAO SYSTEM')}`,
      }
    case '/profile':
      return {
        title: `${t('Your Profile')} | ${t('XCAO SYSTEM')}`,
      }
    default:
      return null
  }
}
